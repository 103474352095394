<template>
	<div class="login-container">
		<div class="w-100 text-white flex-all position-absolute" style="top: 80px;font-size: 42px;">
			{{config.full_name || '安徽电力协会管理系统'}}
		</div>
		<div class="login flex">
			<div class="login-left">
				<el-image :src="loginBg" style="width:100%;height: 100%;"></el-image>
			</div>
			<div class="login-right">
				<el-form ref="loginForm" :model="loginForm" :rules="loginRules" label-width="0" class="login-box" size="large"  @submit.native.prevent>
					<div class="login-header mb-5">
					  <p class="fs_28 font-weight-bold text-000 text-center">欢迎登录</p>
					</div>
					<el-form-item prop="username">
					  <el-input placeholder="账号" prefix-icon="el-icon-user-solid" v-model="loginForm.username" autocomplete="off">
					  </el-input>
					</el-form-item>
					<el-form-item prop="password">
					  <el-input :type="pwdType" placeholder="密码" prefix-icon="el-icon-lock" v-model="loginForm.password" autocomplete="off">
						<i slot="suffix" :class="['el-input__icon icon cursor-pointer',suffixIcon]" @click="showPwd"></i>
					  </el-input>
					</el-form-item>
					<el-checkbox v-model="rememberPwd">记住密码</el-checkbox>
					<el-form-item>
					  <el-button :loading="loading" type="primary" class="w-100 fs_16 mt-5" native-type="submit" @click="onSubmit()" style="background-color: #197FE7;">登录</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
<!-- 		<div class="w-100 text-white flex-all position-absolute" style="bottom: 35px;font-size: 16px;">
			安徽九猫信息科技有限公司©2023版权所有
		</div> -->
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	export default{
		data() {
			const validatePassword = (rule, value, callback) => {
			  if (value.length < 6) {
				callback(new Error('请填写6位数以上密码'))
			  } else {
				callback()
			  }
			}
			return {
				loading: false,
				configName:'',
				pwdType:"password",
				suffixIcon:'icon-eye2',
				rememberPwd:true,
				loginBg:require('@/assets/images/login_bg.png'),
				loginForm:{
					username:'',
					password:'',
				},
				loginRules: {
					username: [{ required: true, message: '请输入您的账号', trigger: 'blur' }],
					password: [{ required: true, trigger: 'blur', validator: validatePassword }]
				},
			}
		},
		computed: {
		   ...mapGetters([
		         'config'
		   ])
		},
		methods:{
			showPwd(){
				   this.pwdType === 'password' ? this.pwdType = '' : this.pwdType = 'password';
				   this.pwdType == '' ? this.suffixIcon = 'icon-eye-blocked2' : this.suffixIcon = 'icon-eye2';
			},
			onSubmit(){
				this.$refs.loginForm.validate(valid => {
				        if (valid) {
							this.loading = true
						    this.$axios.post('/manage/login', this.loginForm).then((res) => {
								if(res.status){
									// 存值给cookies
									this.VueCookies.set('token', res.data.token,{expires:7});
									this.VueCookies.set('userId', res.data.id,{expires:7});
									this.VueCookies.set('userName', res.data.username,{expires:7});
									this.VueCookies.set('avatar', res.data.url,{expires:7});
									//跳转
									var redirect = this.$route.query.redirect;
									if(redirect){
									  this.$router.push(redirect);
									}else{
									  this.$router.push("/");
									}
								}else{
									this.$notify({
									  title: "登录失败",
									  message: res.msg,
									  type: 'warning',
									  duration: 3000,
									});
								}
								  
								this.loading = false
							})
						} else {
						  console.log('error submit!!')
						  return false
						}
				})
				
			},
		}
	}
</script>

<style scoped lang="scss">
.login-container{
  background-color:#F3F7FF;
  height: 100vh;
  position: relative;
  background: url('../../assets/images/login-bg.png') no-repeat;
  background-size: cover;
  .login{
	width: 60%;
	height: 60%;
	position: absolute;
	top:52%;
	left:50%;
	transform: translate(-50%,-50%);
	border-radius:18px;
	box-shadow: 0px 4px 20px 4px rgba(77, 130, 234, 0.4); 
	background-color: #fff;
  }
  .login-left{
	box-sizing: border-box;
	width:60%;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  .login-right{
	width:40%;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  .login-box{
	width: 70%;
	margin: 0 auto;
	.login-header{
	  margin-top: 50px;
	}
  }
}
</style>